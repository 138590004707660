import React, { useState } from 'react'
import { motion } from "framer-motion"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SocialIcon } from '../index.js';
import Modal from 'react-modal';
import { Tooltip } from 'react-tooltip'
import { FaVideo } from "react-icons/fa";

import '../../styles/DeskMatCard.scss';
import { ExternalLink } from './ExternalLink';

export const DeskMatCard = ({id, title="", desc="", className="", img, links, type, videoPreview}) => {
   const [videoPreviewIsOpen, setVideoPreviewIsOpen] = useState(false);
  const customVideoPreviewStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      border: '2px solid transparent !important'

    },
  };
  function openVideoPreview() {
    setVideoPreviewIsOpen(true);
  }

  function afterOpenVideoPreview() {
    // references are now sync'd and can be accessed.
    console.log("preview open")
  }

  function closeVideoPreview() {
    setVideoPreviewIsOpen(false);
  }
  return (
      <motion.div id={id}
        className={`${className} deskmat-card`}
        style={{backgroundImage:img}}
        //animate
      >
           {/* <img src={img} alt={title} loading='lazy' /> */}
            <a 
               href={links.large} 
               target='_blank'
               rel='noreferrer'>
               <LazyLoadImage 
                  alt={title}
                  src={img}
                  effect='blur'
                  placeholderSrc={img}
               />
            </a>
            
            <div className='h-100 d-flex flex-column p-2 justify-content-between'>
               <div>
                  <div className='d-flex justify-content-between'>
                     <h3>{title}</h3>
                     {(videoPreview) ? <div
                        className='deskmat-icon'
                        data-tooltip-id={`${id}-preview`}
                        data-tooltip-content="Preview Desk Mat"
                        onClick={openVideoPreview}
                        >
                        <SocialIcon
                           className={"mt-1"}
                           icon={<FaVideo className='FaVideo'></FaVideo>}
                           checkLink={false}
                        />
                        </div>
                     : ""
                     }
                  </div>
                  
                  <p>{desc}</p>
               </div>
               <div className='d-flex gap-2 justify-content-end'>
                  {(links.large !== "" ) ? <ExternalLink id={id} url={links.large} text='31.5 x 15.5"' className='deskmat-link-border' /> : ""}
                  {(links.medium !== "" ) ? <ExternalLink id={id} url={links.medium} text='22 x 12"' className='deskmat-link-border' /> : ""}
                  {(links.small !== "" ) ? <ExternalLink id={id} url={links.small} text='18 x 12"' className='deskmat-link-border' /> : ""}
               </div>
            </div>
            <Modal
            
            id="preview-modal"
            isOpen={videoPreviewIsOpen}
            onAfterOpen={afterOpenVideoPreview}
            onRequestClose={closeVideoPreview}
            style={customVideoPreviewStyles}
            contentLabel="Video Preview Modal"
            ariaHideApp={false}
         >
            <div className='d-flex align-items-center justify-content-center'>
               <iframe 
               id="preview-video-iframe"
               src={videoPreview} 
               title="Preview Desk Mat"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
               allowFullScreen
               ></iframe>
            </div>
            
         </Modal>
         
         <Tooltip id={`${id}-preview`} />
      </motion.div>
    )
}

// export default DeskMatCard