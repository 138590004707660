export const affiliateData = [
   {
      "id": "nuphy",
      "className": "", 
      "title": "10% OFF @ NuPhy",
      "desc": "Use Code SULCE for 10% off at checkout.",
      "img": require("../../imgs/Affiliates/nuphy-logo.jpg"),
      "url": "https://nuphy.com/sulce",
      "type": "highlight"
   },
   {
      "id": "womier",
      "className": "", 
      "title": "10% OFF @ Womier",
      "desc": "Use Code SULCE for 10% off at checkout.",
      "img": require("../../imgs/Affiliates/womier-logo.jpg"),
      "url": "https://womierkeyboard.com?sca_ref=6103295.YmVMPX9mS1",
      "type": "highlight"
   },
   {
      "id": "goblin-techkeys",
      "className": "", 
      "title": "Goblin Techkeys",
      "desc": "Use Code SULCE for 10% off at checkout.",
      "img": require("../../imgs/Affiliates/goblin-techkeys.png"),
      "url": "https://epomaker.com?sca_ref=6035837.ULc8gdFMXu",
      "type": "highlight"
   },
   {
      "id": "epomaker",
      "className": "", 
      "title": "Epomaker",
      "desc": "All things Epomaker.",
      "img": require("../../imgs/Affiliates/epomaker-logo.png"),
      "url": "https://epomaker.com?sca_ref=6035837.ULc8gdFMXu",
      "type": "highlight"
   },
   {
      "id": "amazon-store-front",
      "className": "", 
      "title": "Amazon StoreFront",
      "desc": "All things Amazon.",
      "img": require("../../imgs/Affiliates/amazon-logo.jpg"),
      "url": "https://www.amazon.com/shop/sulce",
      "type": "highlight"
   },
]