import {React} from 'react'

import '../styles/Giveaways.scss';
import '../styles/MainBody.scss';
import { GiveawayCard } from './index.js';

export const Giveaways = ({giveawayData}) => {
   console.log(giveawayData);
   let GiveawayFinal = []
   let returnTrue = false
   
   giveawayData.forEach((element, id) => {
      let isOpen = false;

      let startDate = new Date(element.start);
      let endDate = new Date(element.end);
      let currentDate = new Date()

      console.log("Start: " + startDate)
      console.log("Current: " + currentDate)
      console.log("End: " + endDate)

      if(currentDate > startDate && currentDate < endDate) {
         isOpen = true
      } else {
         console.log('is not valid');
      }

      if(isOpen) {
         GiveawayFinal.push(<GiveawayCard
                key={element.id} 
                id={element.id} 
                title={element.title}
                className={element.className}
                url={element.url}
              />)
         returnTrue = true
      }
    });
   return (
      <>{(returnTrue) 
         ?  <div id="giveaways">
               <div className='d-flex flex-column giveaway'>
                  <h3>Giveaways</h3>
                  {GiveawayFinal}
               </div>
            </div>
         : <></>
      }</>
      
  )
}

// export default Giveaways