import React from 'react'
import '../../styles/ExternalLink.scss';

export const ExternalLink = ({id, className="", url, text=""}) => {

  return (
    <a 
      id={id}
      className={`${className} external_link`}
      href={url} 
      target='_blank'
      rel='noreferrer'>
        {text}
    </a>

  )
}

// export default ExtenalLink