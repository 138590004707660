import React, { useState  } from 'react'
import { motion } from "framer-motion"
import Modal from 'react-modal';

import $ from 'jquery';

import '../styles/Footer.scss';
import '../styles/SocialIcon.scss';
import { SocialIcon, KeyboardTester } from './index.js';

export const Footer = ({url, socialData}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'var(--black)'
    },
  };
  
  const handleResize = () => {
    setWindowSize(window.innerWidth)
  }

  function openModal() {
    setIsOpen(true);

    window.setTimeout(function(){
      $('.loading-container')
        .addClass('opacity-0')
        .removeClass('opacity-1')
    }, 500)

    window.setTimeout(function(){
      $('.loading-container')
        .addClass('d-none')
        .removeClass('d-flex')
    }, 1000)
  }


  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    console.log("modal is open")
  }

  function closeModal() {
    setIsOpen(false);
    $('.loading-container')
      .addClass('d-flex')
      .removeClass('d-none')
      .addClass('opacity-1')
      .removeClass('opacity-0')
  }


  window.addEventListener('resize', handleResize)

  let firstTwoSocialData = socialData.slice(0, 2);

  const firstTwoIconsElement = firstTwoSocialData.map((element, id) => {
    return <a key={element.id} className='separate_icons' style={{"cursor":"pointer"}} href={element.url} target='_blank' rel="noreferrer">
      <SocialIcon
       id={element.id} 
       className={element.className}
       icon={element.icon}
       url={element.url}
       checkLink={false}
      />
      <p>{element.id}</p>
    </a>
 })

 let secondTwoSocialData = socialData.slice(2, 4);

  const secondTwoIconsElement = secondTwoSocialData.map((element, id) => {
    return <a key={element.id} className='separate_icons' style={{"cursor":"pointer"}} href={element.url} target='_blank' rel="noreferrer">
      <SocialIcon
       id={element.id} 
       className={element.className}
       icon={element.icon}
       url={element.url}
       checkLink={false} 
      />
      <p>{element.id}</p>
    </a>
 })



  return (
    <div id='footer'>
      <div id="top-layer-container">
         <div id="footer-top-layer">
            {(windowSize > 550) 
              ? <>
                  <div className="w-25 seperate_icons_container justify-content-start social_container">
                    Created by sulce
                  </div>
                  {/* <div onClick={openModal}>
                    Keyboard Tester
                  </div> */}
                  <a id="footer-qoute" href={url} target='_blank' rel="noreferrer"  className="d-flex justify-content-end align-items-center"><h3>Come find, explore, and share tech with me</h3></a>
                  <div className="seperate_icons_container justify-content-end social_container">
                    {firstTwoIconsElement}
                    {secondTwoIconsElement}
                  </div>
                </> 
              
              : <>
                  <div className="seperate_icons_container justify-content-center social_container">
                      {firstTwoIconsElement}
                      {secondTwoIconsElement}
                  </div>
                  <motion.a 
                    id="footer-qoute" href={url} target='_blank' rel="noreferrer"  
                    className="d-flex justify-content-end align-items-center" 
                    whileHover={{scale: 1.01}} whileTap={{scale: 0.90}}>
                      <h3>Come find, explore, and share tech with me</h3>
                  </motion.a>
                  <div className="seperate_icons_container justify-content-start social_container">
                    Created by SULCE
                  </div>
                </> 
            }
         </div>
      </div>
      {/* <Modal
        id='products-modal'
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Full Products List Modal"
        ariaHideApp={false}>
          <KeyboardTester />
      </Modal> */}
    </div>
  )
}

// export default Footer
