
export const myProductListData = [
   {
      "id": "dr-stream-deck-profile",
      "title": "Davinci Resolve Basics Profile",
      "desc": "Free Stream Deck Profile - DR Basics Free Stream Deck Profile..",
      "className": "", 
      "img": require("../../imgs/MyProducts/DRStream-Deck-Profile.png"),
      "url":"https://sulce.gumroad.com/l/dr-basics-stream-deck-profile",
      "type": "all, software, other", 
      "preview": "https://www.youtube.com/embed/aFmC-HiDcns" 
   },
   {
      "id": "autumn-o-mock",
      "title": 'Autumn - O',
      "desc": "Autumn Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../../imgs/MyProducts/autumn-o-mock.jpg"),
      "url": "https://sulce.shop/products/autumn-o-l-desk-mat",
      "type": "all, deskmat", 
      "preview": ""
   },
   {
      "id": "p03-waves-mock",
      "title": 'P03 - Waves',
      "desc": "Waves Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../../imgs/MyProducts/p03-waves-mock.jpg"),
      "url": "https://sulce.shop/products/p03-waves-l-desk-mat",
      "type": "all, deskmat", 
      "preview": ""
   },
   {
      "id": "pumpkin-waves-mock",
      "title": 'Pumpkin - Waves',
      "desc": "Waves Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../../imgs/MyProducts/pumpkin-waves-mock.jpg"),
      "large": "https://sulce.shop/products/pumpkin-waves-l-desk-mat",
      "type": "all, deskmat", 
      "preview": ""
   },
   {
      "id": "pink-stellar-mock",
      "title": 'Pink - Stellar',
      "desc": "Stellar Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../../imgs/MyProducts/pink-stellar-mock.jpg"),
      "url": "https://sulce.shop/products/pink-stellar-l-desk-mat",
      "type": "all, deskmat", 
      "preview": ""
   },
   {
      "id": "set02-collection",
      "title": 'SET02 Collection',
      "desc": "Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../../imgs/MyProducts/set02-collection.png"),
      "url": "https://sulce.shop/collections/set02",
      "type": "all, deskmat", 
      "preview": ""
   },
   {
      "id": "desk-mat-collection",
      "title": 'Desk Mat. Collection',
      "desc": "Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../../imgs/MyProducts/desk-mat-collection.png"),
      "url": "https://sulce.shop/collections/desk-mat",
      "type": "all, setup",
      "preview": ""
   },
   {
      "id": "purple-cozy-drive-mock",
      "title": 'Purple - Cozy Drive',
      "desc": "Cozy Drive Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../../imgs/MyProducts/purple-cozy-drive-mock.jpg"),
      "url": "https://sulce.shop/products/purple-cozy-drive-l",
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/alj_KFRRsyk"
   },
   {
      "id": "purple-stellar-mock",
      "title": 'Purple - Stellar',
      "desc": "Stellar Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../../imgs/MyProducts/purple-stellar-mock.jpg"),
      "url": "https://sulce.shop/products/purple-stellar-l",
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/alj_KFRRsyk"
   },
   {
      "id": "p01-waves-mock",
      "title": 'P01 - Waves',
      "desc": "Waves Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../../imgs/MyProducts/p01-waves-mock.jpg"),
      "url": "https://sulce.shop/products/p01-waves-l",
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/alj_KFRRsyk"
   },
   {
      "id": "blue-waves-mock",
      "title": 'Blue Waves',
      "desc": "Waves Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../../imgs/MyProducts/blue-waves-mock.jpg"),
      "url": "https://sulce.shop/products/blue-waves-l",
      "type": "all, setup, highlight", 
      "preview": "https://www.youtube.com/embed/alj_KFRRsyk"
   },
   {
      "id": "set01-collection",
      "title": 'SET01 Collection',
      "desc": "Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../../imgs/MyProducts/set01-collection.png"),
      "url": "https://sulce.shop/collections/set01",
      "type": "all, deskmat", 
      "preview": "https://www.youtube.com/embed/alj_KFRRsyk"
   },
]
