import {React, useState} from 'react'
import { motion } from "framer-motion"
import { AffiliateCard } from './index.js';

import $ from 'jquery';
import '../styles/Affiliates.scss';

export const Affiliates = ({affiliateData}) => {
   const [searchFilter, setSearchFilter] = useState('all')

   let affiliateSearchCount = 0

   let AllAffiliatesList = affiliateData.map((element, id) => {
      return <AffiliateCard
          key={element.id}
          id={element.id}
          title={element.title}
          desc={element.desc}
          className={'m-0'}
          img={element.img} 
          url={element.url}
      />
  })

  let finalAffiliatesSearchList = []
  const handleAffiliateSearch = (e) => {
    affiliateSearchCount = 0
    affiliateData.forEach((element, id) => {
      if(element.title.toLowerCase().includes(e.target.value.toLowerCase()
      || element.desc.toLowerCase().includes(e.target.value.toLowerCase()))
      ) {
        affiliateSearchCount++;
        finalAffiliatesSearchList.push(<AffiliateCard
          key={element.id}
          id={element.id}
          title={element.title}
          desc={element.desc}
          className={'m-0'}
          img={element.img} 
          url={element.url}
        />)
      }
    })

    if(affiliateSearchCount === 0) {
      finalAffiliatesSearchList = <div className="d-flex mx-2"><p>No Results Found</p></div>
      setAffiliatesShown(AllAffiliatesList)
    } else {
      setAffiliatesShown(finalAffiliatesSearchList)
    }
  }


  const [affiliatesShown, setAffiliatesShown] = useState(AllAffiliatesList)

  return (
    <div id="affiliates">
      {/* <h3>Highlight</h3>
      <div className="pb-0 affiliates-container">
        {HighlightProductsList}
      </div> */}

      <div id="short-affiliate-container">
        <h3 className='mt-3'>Discounts & Affiliates</h3>
        <div className="affiliate-container">
          <input id="short-product-search" type="text" placeholder="Search..." onChange={handleAffiliateSearch} />
        </div>
      </div>
      <div className="pb-0 affiliates-container">
        {affiliatesShown}
        <div></div>
      </div> 
    </div>
   )
}

// export default Products