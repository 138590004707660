import {React} from 'react'

import '../../styles/MainBody.scss';
import { ExternalLink } from '../index.js';

export const GiveawayCard = ({id, title, className, url}) => {
   return (
       <div className={`${className}`}
         >
         <ExternalLink 
            id={`${id}-giveaway-button`}
            className="giveaway-button"
            url={url} 
            text={title}
         />
      </div> 
)
}

// export default GiveawayCard