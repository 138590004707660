export const giveawayData = [
   {
      "id": "akko-cream-black-pro-v3-giveaway",
      "title": "Akko Cream Black V3 Pro - Ends 9/2",
      "className": "", 
      "url": "https://gleam.io/hi269/sulce-akko-cream-black-v3-pro-giveaway",
      "start": "2024-08-16T00:00:00-08:00",
      "end": "2024-09-03T23:59:00-08:00"
   },
   {
      "id": "ws-morandi-giveaway",
      "title": "WS Morandi - Ends 9/25",
      "className": "", 
      "url": "https://gleam.io/3XhpS/sulce-ws-morandi-giveaway",
      "start": "2024-09-14T00:00:00-08:00",
      "end": "2024-09-26T23:59:00-08:00"
   },
]