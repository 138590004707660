import React from 'react'
import '../../styles/SocialIcon.scss';

export const SocialIcon = ({id, className='', icon='', url, checkLink=true}) => {
  return (
  <>
    {(checkLink)
    ? <a 
        className={`${className} social_icon`}
        href={url}
        target='_blank'
        rel='noreferrer'
      >
        {icon}
      </a>
    : <div 
        className={`${className} social_icon`}
      >
        {icon}
      </div>
    }
    </>
  )
}

// export default SocialIcon